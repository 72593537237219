









































































































































.contact {
  @apply rounded;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply cursor-pointer;
  @apply bg-deal;
  @apply w-8;
  @apply h-8;
}
